<template>
  <div class="gradientBK" style="background: #e4e4f9">
    <div class="cursorjont last-pro-cursor">Ver más</div>
    <div class="transp-cursor"></div>

    <div class="B1 gradientBK" style="background: #e4e4f9">
      <Header />
      <div class="B1F2">
        <div class="B1F2_B1">
          <p class="B1T1">To make you grow</p>
          <p class="B1T2">
            Nuestros servicios de branding, desarrollo web y marketing digital nos lleva a dar experiencias integrales donde le damos el máximo potencial a tu marca.
          </p>
        </div>
        <div class="B1F2_B2">
          <img src="../assets/Frame2.png" />
        </div>
      </div>
    </div>
    <a href="/services/development">
      <div class="B2">
        <div
          class="B2F1 servFrame1 pc"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="1000"
        >
          <div class="two-phone">
            <img src="../assets/Recurso31.png" class="B2F1_I1" />
            <div class="B2B2">
              <p class="idsec">01</p>
              <p class="B2Title">Development</p>
              <p class="B2Text B2Textuno">
                La transformación digital es inminente, ¿para qué seguir esperando? Con expertos en desarrollo web, hemos formado un gran equipo con las habilidades necesarias para hacer realidad cualquier tipo de plataforma digital y unirte a la nueva era.
              </p>
              <p class="B2Text">
                Todos nuestros proyectos son diseñados desde una perspectiva UI (Interfaz de Usuario) y UX (Experiencia de Usuario) para hacerlos perfectamente funcionales en los objetivos de tu marca. 
              </p>
              <ul class="B2List">
                <li>e-commerce</li>
                <li>web design</li>
                <li>mobile apps</li>
                <li>ui / ux</li>
                <li>landing pages</li>
              </ul>
            </div>
          </div>

          <div class="two-phone">
            <div
              class="B2PH1"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img class="B2C1" src="../assets/Frame3.png" />
              <img class="B2I1" src="../assets/Mockup_Mobile2.png" />
            </div>
            <div
              class="B2PH2"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img class="B2C2" src="../assets/Frame3.png" />
              <img class="B2I2" src="../assets/Mockup_Mobile1.png" />
            </div>
          </div>
          <div class="rowbtnvermov"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="300">
            <a class="btnvermov" href="/services/development">
              <p>Conoce</p>
              <p>más</p> 
            </a>
          </div>
        </div>
        <div
          class="B2F1 servFrame1 mo"
        >
          <div class="two-phone">
            <img src="../assets/Recurso31.png" class="B2F1_I1" />
            <div class="B2B2">
              <p class="idsec">01</p>
              <p class="B2Title">Development</p>
              <p class="B2Text B2Textuno">
                La transformación digital es inminente, ¿para qué seguir esperando? Con expertos en desarrollo web, hemos formado un gran equipo con las habilidades necesarias para hacer realidad cualquier tipo de plataforma digital y unirte a la nueva era.
              </p>
              <p class="B2Text">
                Todos nuestros proyectos son diseñados desde una perspectiva UI (Interfaz de Usuario) y UX (Experiencia de Usuario) para hacerlos perfectamente funcionales en los objetivos de tu marca. 
              </p>
              <ul class="B2List">
                <li>e-commerce</li>
                <li>web design</li>
                <li>mobile apps</li>
                <li>ui / ux</li>
                <li>landing pages</li>
              </ul>
            </div>
          </div>

          <div class="two-phone">
            <div
              class="B2PH1"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img class="B2C1" src="../assets/Frame3.png" />
              <img class="B2I1" src="../assets/Mockup_Mobile1.png" />
            </div>
            <div
              class="B2PH2"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img class="B2C2" src="../assets/Frame3.png" />
              <img class="B2I2" src="../assets/Mockup_Mobile2.png" />
            </div>
          </div>
          <div class="rowbtnvermov"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="300">
            <a class="btnvermov" href="/services/development">
              <p>Conoce</p>
              <p>más</p> 
            </a>
          </div>
        </div>
        <!--  <div class="B2F2">
                <img class="B2F2_I1" src="../assets/Rectangle65.png">
                <img class="B2F2_I2" src="../assets/Rectangle63.png">
                <img class="B2F2_I3" src="../assets/Rectangle63.png">
            </div>-->
      </div>
    </a>
    <a href="/digital-marketing ">
      <div
        class="B3 servFrame2"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <div class="B3F1">
          <div class="rowbtnvermov rowbtndigi">
            <a class="btnvermov btnvmdigi" href="/agencia-de-marketing ">
              <p>Conoce</p>
              <p>más</p> 
            </a>
          </div>
          <img class="B3F1_I1" src="../assets/Group18.png" />
          <div class="two-phone">
            <img class="B3F1_I2" src="../assets/Recurso.png" />
            <div
              class="B3F1_B3"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <p class="idsec">02</p>
              <p class="B3Title">Digital Marketing</p>
              <p class="B3Text B3Textuno">
                Hacemos campañas de marketing digital integrales que comuniquen con estrategia y creatividad los objetivos de tu marca.
              </p>
              <p class="B3Text">
                Contamos con un equipo multidisciplinario formado por redactores, creativos, diseñadores y analistas que forman un team extraordinario y disruptivo.
              </p>
              <ul class="B3List">
                <li>strategy</li>
                <li>digital comunication</li>
                <li>social media</li>
                <li>content production</li>
                <li>community management</li>
                <li>google ads</li>
                <li>analytics</li>
              </ul>
            </div>
          </div>
          
        </div>
        <!--   <div class="B3F2">
                <img class="B3F2_I1" src="../assets/Rectangle101.png">
                <img class="B3F2_I2" src="../assets/Rectangle99.png">
                <img class="B3F2_I3" src="../assets/Rectangle63.png">
            </div>-->
      </div>
    </a>
    <a href="/branding">
      <div
        class="B4 servFrame3"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <div
          class="B4F1"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="two-phone">
            <img class="B4I1" src="../assets/Recurso11.png" />
            <div class="B4F2">
              <p class="idsec">03</p>
              <p class="B4Title">Branding y Naming</p>
              <p class="B4Text B4Textuno">
                Nos especializamos en crear una imagen visual impactante y un nombre que conecte de manera perfecta con tu mercado objetivo. Trabajamos siempre con creatividad y dedicación para ofrecer resultados únicos y modernos.
              </p>
              <p class="B4Text">
                Confía en nosotros para hacer que tu marca se destaque y deje una impresión duradera.
              </p>
              <ul class="B4List">
                <li>Branding</li>
                <li>Naming</li>
                <li>visual identity</li>
              </ul>
            </div>
          </div>

          <img class="B4I2" src="../assets/branding.png" />
          <div class="rowbtnvermov rowbtndesi">
            <a class="btnvermov btnvmdesi" href="/branding">
              <p>Conoce</p>
              <p>más</p> 
            </a>
          </div>
        </div>
        <!-- <div class="B4F2">
                <img class="B4F2_I1" src="../assets/Rectangle63.png">
                <img class="B4F2_I2" src="../assets/Rectangle63.png">
                <img class="B4F2_I3" src="../assets/Rectangle63.png">
            </div>-->
      </div></a
    >

    <div
      class="B5"
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-duration="1000"
      data-aos-delay="100"
    >
      <p>Trabajemos juntos, <span>cotiza aquí.</span></p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue";

export default {
  name: "Services",
  components: {
    Header,
    Footer,
  },
  methods: {
    changeBackgroundPartners(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("gradientBK")[0];
      let divcont1 = document.getElementsByClassName("gradientBK")[1];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      console.log("biza " + offset);
      if (media768.matches) {
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      } else if (min768.matches) {
        /**#e4e4f9 */
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.changeBackgroundPartners);

    let cursor = document.querySelector(".cursorjont");
    let cursorinner = document.querySelector(".transp-cursor");
    let sf1 = document.querySelectorAll(".servFrame1");
    let sf2 = document.querySelectorAll(".servFrame2");
    let sf3 = document.querySelectorAll(".servFrame3");

    document.addEventListener("mousemove", function (e) {
      let x = e.clientX;
      let y = e.clientY;
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%),
   calc(${e.clientY}px - 50%), 0)`;
    });

    document.addEventListener("mousemove", function (e) {
      let x = e.clientX;
      let y = e.clientY;
      cursorinner.style.left = x + "px";
      cursorinner.style.top = y + "px";
    });

    document.addEventListener("mousedown", function () {
      cursor.classList.add("click");
      cursorinner.classList.add("last-pro-cursor");
    });

    document.addEventListener("mouseup", function () {
      cursor.classList.remove("click");
      cursorinner.classList.remove("last-pro-cursor");
    });

    sf1.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });

    sf2.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });

    sf3.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Services - Legrafica';
            }
        },
  }
};
</script>

<style scoped>
.pc{
  display: flex!important;
}
.mo{
  display: none!important;
}
.rowbtnvermov {
  display: none;
}
a:active {
    color: #987ef3;
}
.cursorjont,
.transp-cursor,
.cursorinnerhover {
  width: 2vh;
  height: 2vh;
  border: 2px solid transparent;
  background-color: transparent;
  font-family: "Gramatika-Bold";
  font-size: 1.609375vh;
  line-height: 152.3%;
  text-align: center;
  color: transparent;
  position: fixed;
  z-index: 345;
  pointer-events: none;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 0.1s !important;
}

.stories-mas-cont {
  border-radius: 30vh !important;
  /*width: 10.781vw!important;
    height: 10.781vw!important;*/
  width: 10.015625vh !important;
  height: 10.015625vh !important;
  border: 2px solid #396aff !important;
  color: #ffffff !important;
  background-color: #396aff !important;
  transition: 0.1s !important;
}
.servFrame1,
.servFrame2,
.servFrame3 {
  cursor: pointer;
}
.B1 {
  width: 100%;
  height: 71.71875vw;
  /*background: linear-gradient( #E4E4F9 80%, #FFFFFF );*/
  background: #e4e4f9;
}
.Header {
  display: flex;
  width: 92.5vw;
  height: 10.572916666666666vw;
  padding: 0vw 3.28125vw;
  margin: 0vw;
  justify-content: space-between;
}
.HB1 {
  display: flex;
}
.HB1 img {
  width: 10.260416666666666vw;
  height: auto;
  margin-right: 2.5vw;
  margin-bottom: -6vw;
}
.HB1 p {
  color: #ef67c2;
  font-size: 0.5208333333333333vw;
  align-self: center;
}
.HB2 {
  display: flex;
  z-index: 1;
}
.HB2 a {
  align-self: center;
}
.HB2 p {
  color: #987ef3;
  font-size: 1.1458333333333333vw;
  align-self: center;
}
.HB2 img {
  width: 3.90625vw;
  height: auto;
  margin-left: 2.5vw;
}

.B1F2_B1 {
  margin-left: 8.59375vw;
  z-index: 1;
}
.B1F2 {
  display: flex;
}
.B1T1 {
  width: 42.5vw;
  color: #ef67c2;
  font-size: 7.813vw;
  line-height: 9.4765625vw;
  /*font-weight: 500;*/
  margin: 17.625vw 0 5.073vw;
  font-family: "Gramatika-Medium";
}
.B1T2 {
  width: 30vw;
  font-size: 0.8333333333333334vw;
  line-height: 1.4984374999999999vw;
  font-family: "IBM Plex Mono";
}
.B1F2_B2 {
  width: 100%;
  margin-left: -54.7vw;
}
.B1F2_B2 img {
  width: 54.708333vw;
  /*margin-top: -11vw;*/
  float: right;
}
.B3 {
  margin-bottom: 26.365vw;
  margin-left: 10.260416666666666vw;
}

.B2F1 {
  display: flex;
  margin-bottom: 30.365vw;
  margin: 0 10.15625vw 26.25vw;
}
.B2F1_I1 {
  width: 5vw;
  height: 5vw;
  margin-top: 26vw;
  margin-right: 2.864583333333333vw;
}
p.idsec {
    margin: 0;
    font-size: 0.5729166666666666VW;
    color: #EF67C2;
    font-family: "Gramatika-medium";
}
p.B2Title {
  color: #987ef3;
  font-size: 2.083333333333333vw;
  margin: 0;
  margin-bottom: 3.28125vw;
}
.B2Textuno {
  margin-bottom: 2vw !important;
}
p.B2Text {
  width: 20vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.1213541666666667vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 3.58125vw;
  color: #000000;
}
ul.B2List {
  padding-left: 0vw;
}
.B2List li {
  width: 18.5vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.7994791666666665vw;
  font-family: "IBM Plex Mono";
  list-style: none;
  color:#000000;
}
.two-phone {
  display: flex;
}
.B2PH1 {
  margin-right: 2.864583333333333vw;
  margin-left: 12.552083333333334vw;
}
img.B2C1 {
  width: 15.052083333333332vw;
  margin-right: -14vw;
}
img.B2I1 {
  width: 12.760416666666666vw;
  margin-bottom: -4vw;
}
img.B2C2 {
  width: 15.052083333333332vw;
  margin-right: -14vw;
}
img.B2I2 {
  width: 12.760416666666666vw;
  margin-bottom: -4vw;
}

.B2F2 {
  margin-top: 6vw;
  margin-bottom: 15vw;
  padding-left: 15.885416666666666vw;
}
img.B2F2_I1 {
  width: 15.885416666666666vw;
}
img.B2F2_I2 {
  width: 13.28125vw;
  margin-left: 7vw;
  margin-bottom: 9vw;
}
img.B2F2_I3 {
  width: 21.302083333333332vw;
  margin-left: 13.28125vw;
  margin-bottom: -4vw;
}

.B3F1 {
  display: flex;
}
img.B3F1_I1 {
  width: 40.625VW;
  height: 28.44739583333334VW;
  margin-right: 7.447916666666667vw;
}
img.B3F1_I2 {
  width: 5vw;
  height: 5vw;
  margin-top: 20vw;
  margin-right: 2.864583333333333vw;
}
p.B3Title {
  color: #987ef3;
  font-size: 2.083333333333333vw;
  margin-bottom: 3.28125vw;
  margin-top: 0;
}
p.B3Text {
  width: 21vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.1213541666666667vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 2.28125vw;
  color:#000000;
}
.B3Textuno {
  margin-bottom: 2vw !important;
}
ul.B3List {
  padding-left: 0vw;
  margin-top: 0;
}
.B3List li {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.7994791666666665vw;
  font-family: "IBM Plex Mono";
  list-style: none;
  color: #000000;
}

.B3F2 {
  padding-top: 9.365104166666667vw;
  padding-left: 7vw;
  margin-bottom: 15.625vw;
}
img.B3F2_I1 {
  width: 28.385416666666668vw;
  margin-right: 6vw;
  margin-bottom: 18vw;
}
img.B3F2_I2 {
  width: 12.291666666666666vw;
  margin-right: 12vw;
}
img.B3F2_I3 {
  width: 21.302083333333332vw;
  margin-bottom: 19vw;
}

.B4 {
  margin-left: 10.416666666666668vw;
}
.B4F1 {
  display: flex;
}

img.B4I1 {
  width: 5vw;
  height: 5vw;
  margin-top: 20vw;
  margin-right: 2.864583333333333vw;
}
p.B4Title {
  color: #987ef3;
  font-size: 2.083333333333333vw;
  margin-bottom: 3vw;
  margin-top: 0;
}
p.B4Text {
  width: 21vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.1213541666666667vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 2.28125vw;
  color: #000000;
}
.B4Textuno {
  margin-bottom: 2vw !important;
}
ul.B4List {
  padding-left: 0vw;
  margin-top: 0;
}
.B4List li {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.7994791666666665vw;
  font-family: "IBM Plex Mono";
  list-style: none;
  color: #000;
}
img.B4I2 {
  width: 41.5625vw;
  margin-left: 9.010416666666666vw;
  margin-top: -6vw;
}

img.B4F2_I1 {
  width: 21.302083333333332vw;
  padding-bottom: 10vw;
  margin-right: 6.302083333333333vw;
  margin-left: 1vw;
}
img.B4F2_I2 {
  width: 21.302083333333332vw;
  padding-bottom: 5vw;
  margin-right: 6.302083333333333vw;
}
img.B4F2_I3 {
  width: 21.302083333333332vw;
}

.B5 p {
  width: 61vw;
  font-size: 6.25vw;
  line-height: 7.581250000000001vw;
  color: #ef67c2;
  margin: 19.71875vw 0vw;
  margin-left: 21.666666666666668vw;
}
.B5 span {
  color: #987ef3;
}

@media (max-width: 768px) {
.pc{
  display: none!important;
}
.mo{
  display: block!important;
}
  .rowbtnvermov {
  display: flex;
  margin-top: 22.666666666666664VW;
  height: 27.053140096618357VW;
  align-items: center;
  justify-content: center;
  text-align: center;
  }
  .btnvermov{
    font-size: 3.140096618357488VW;
    line-height: 3.352657004830918VW;
    font-family: "Gramatika-bold";
    color:#ffffff;
    background-color: #3702FB;
    /*padding: 11.111vw 8vw 9vw;*/
    padding: 11.111vw 0 0;
    border-radius: 50%; 
    width: 27.053vw;
    height: 15.942vw;
  }
  .btnvermov p {
    margin: 0;
  }
  .rowbtndigi {
    margin-top: 18vw;
    width: 85%;
  }
  .btnvmdigi {
    background: #EF67C2;
  }
  .btnvmdesi {
    background: #851BFF;
  }
  .rowbtndesi {
    width: 85%;
  }
  .B1 {
    width: 100%;
    height: 117.72vw;
  }
  .B1F2 {
    background-image: url(../assets/pleca_services.png);
    background-repeat: no-repeat;
    background-size: 100vw;
  }
  .B1F2_B1 {
  margin-left: 13.043478260869565VW;
  }
  .B1F2_B2 img {
  display: none;
}
  .B1T1 {
    width: 70%;
    color: #ef67c2;
    font-size: 12.077294685990339VW;
    line-height: 14.64975845410628VW;
    /* font-weight: 500; */
    margin: 40.787vw 0 11.11111111111111VW;
    font-family: "Gramatika-Medium";
  }
  .B1T2 {
    width: 80%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778VW;
  }
  .B2F1 {
    display: flex;
    margin: 0 13.527vw 36.473vw;
    margin-bottom: 31.15942028985507VW;
    flex-direction: column;
  }
  .B1F2_B2 {
    width: 100%;
    margin-left: -54.7vw;
    display: none;  
  }
  p.idsec {
    margin: 0;
    font-size: 2.1739130434782608VW;
    color: #EF67C2;
    font-family: "Gramatika-medium";
  }
  p.B2Title {
    color: #987ef3;
    font-size: 5.797vw;
    margin: 0;
    margin-bottom: 10.386vw;
  }
  .B2F1_I1 {
    width: 16.667vw;
    height: 16.667vw;
    margin-top: 88vw;
    margin-right: 0;
    position: absolute;
  }
  p.B2Text {
    width: 95%;
    font-size: var(--size11mov);
    line-height: 4.086956521739131VW;
    margin-bottom: 13.014493VW;
    color: #000000;
    margin-top: 0;
  }
  .B2Textuno, .B3Textuno, .B4Textuno {
    margin-bottom: 6vw !important;
  }
  ul.B2List {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: auto;
    height: 21.217vw;
    margin: 0 0 21.498vw 26.087vw;
  }
  .B2List li {
    width: 23vw;
    font-size: var(--size11mov);
    line-height: 246.8%;
    color:#000000;
  }
  .B2PH1 {
    margin-right: 6.039vw;
    margin-left: 0;
  }
  img.B2C1,
  img.B2C2 {
    width: 32.126vw;
    margin-right: -30vw;
  }
  img.B2I1,
  img.B2I2 {
    width: 27.760417vw;
    margin-bottom: -8vw;
  }
  .B3F1 {
    display: flex;
    flex-direction: column-reverse;
  }
  p.B3Title {
    color: #987ef3;
    font-size: 5.797vw;
    margin-bottom: 10.386vw;
    margin-top: 0;
  }
  img.B3F1_I2 {
    width: 16.667vw;
    height: 16.667vw;
    margin-top: 68vw;
    margin-right: 0;
    position: absolute;
  }
  p.B3Text {
    width: 95%;
    font-size: var(--size11mov);
    line-height: 153.8%;
    margin-bottom: 10.628vw;
    color:#000000;
  }
  ul.B3List {
    padding-left: 26.087vw;
    margin: 0 0 14.734vw;
  }
  .B3List li {
    font-size: var(--size11mov);
    line-height: 246.8%;
    color:#000000;
  }
  img.B3F1_I1 {
    width: 73.42995169082126VW;
    height: auto;
    margin-right: 0;
  }
  .B3 {
    margin-bottom: 34.05797101449276VW;
    margin-left: 13.043478260869565VW;
  }
  .B4 {
    margin-left: 13.043478260869565VW;
  }
  .B4F1 {
    display: flex;
    flex-direction: column;
  }
  img.B4I1 {
    width: 16.667vw;
    height: 16.667vw;
    margin-top: 74vw;
    margin-right: 0;
    position: absolute;
  }
  p.B4Title {
    color: #987ef3;
    font-size: 5.797vw;
    margin-bottom: 10.386vw;
    margin-top: 0;
  }
  p.B4Text {
    width: 90%;
    font-size: var(--size11mov);
    line-height: 4.086956521739131VW;
    margin-bottom: 12.077vw;
    color: #000000;
  }
  ul.B4List {
    padding-left: 26.087vw;
    margin-bottom: 8.937vw;
  }
  .B4List li {
    font-size: var(--size11mov);
    line-height: 246.8%;
    color: #000000;
  }
  img.B4I2 {
    width: 66.425vw;
    margin-left: 3.010417vw;
    margin-top: 2vw;
  }
  .B5 p {
    width: 59vw;
    font-size: 9.66183574879227VW;
    line-height: 11.719806763285025VW;
    color: #ef67c2;
    margin: 31.71875vw 0vw 25vw;
    margin-left: 21.666666666666668vw;
    text-align: center;
    font-family: "Gramatika-medium";
}
}
</style>
